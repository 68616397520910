<template>
  <div>
    <Popup
      class="create-entity"
      :modal-id="modal_id"
      :on-close="onModalClose"
      custom-class="gray-header"
      :toggle-status="toggleStatus"
    >
      <template v-slot:header>
        {{ labels.create_new_page }}
      </template>

      <template v-slot:body>
        <div class="create-entity__body">
          <FormulateInput
            type="text"
            name="title"
            :placeholder="labels.title"
            validation="required"
            error-behavior="blur"
            v-model="userdata.title"
          />

          <FormulateInput
            type="textarea"
            name="description"
            :placeholder="labels.description"
            validation="required"
            error-behavior="blur"
            v-model="userdata.description"
          />
        </div>
      </template>

      <template v-slot:footer>
        <div class="create-entity__footer">
          <FormulateInput
            type="submit"
            class="btn-purple"
            :label="labels.create"
            form="new-entity"
            @click="createPageMt"
          />

          <Button
            :label="labels.close"
            variant="btn-outline-purple"
            font-weight="600"
            :action="onModalClose"
          />
        </div>
      </template>
    </Popup>
  </div>
</template>

<script>
import Popup from "@/components/Popups/Popup";
import Button from "@/components/Buttons/Button";

import { mapActions, mapGetters, mapState, mapMutations } from "vuex";

export default {
  components: {
    Popup,
    Button,
  },
  data() {
    return {
      toggleStatus: true,
      modal_id: "NewPagePopup",
      userdata: {
        title: "",
        description: "",
      },
    };
  },
  props: {
    type: {
      type: String,
      default: "page",
    },
  },
  computed: {
    ...mapState("helper", ["basicDataLoaded", "features"]),
    ...mapGetters("helper", {
      hasPermissions: "hasPermissions",
      labels: "labels",
    }),
  },
  watch: {
    basicDataLoaded(newValue) {
      // Means basic data loaded, running all needed stuff
      if (newValue) this.processPage();
    },
  },
  methods: {
    ...mapActions("notification", {
      addNotification: "addNotification",
    }),
    ...mapActions("page", {
      createPage: "createPage",
    }),
    ...mapMutations("page", ["setPages"]),
    onModalClose(avoidBack) {
      if (!avoidBack) this.$router.go(-1);
      let element = document.querySelector(".modal-backdrop");
      if (element) {
        element.remove();
        document.querySelector("body").classList.remove("modal-open");
      }
    },
    createPageMt() {
      this.createPage({
        title: this.userdata.title,
        description: this.userdata.description,
      })
        .then((res) => {
          if (res.success) {
            this.toggleStatus = false;
            this.onModalClose(true);
            this.addNotification({
              variant: "success",
              msg: this.labels.page_added,
            });
            const menuPageNum = this.$route.query.page || 1;
            this.setPages([]);
            this.$router.push({
              name: "overview-page",
              params: { page: menuPageNum },
            });
          } else if (res.error) {
            this.addNotification({ variant: "danger", msg: res.error });
          }
        })
        .catch((err) => console.log(err));
    },
    processPage() {
      if (!this.basicDataLoaded) return;

      if (!this.features.websystem_pages || !this.hasPermissions(["super", "web"])) {
        this.$router.push({ name: "dashboard" });
        return;
      }
      console.log("ok");
    },
  },
  mounted() {
    this.processPage();
  },
};
</script>

<style lang="scss" scoped>
.create-entity {
  &__header-title {
    font-family: "Oswald", sans-serif;
  }

  &__body {
    ::v-deep .formulate-input {
      .formulate-input-wrapper {
        label {
          font-family: "Oswald", sans-serif;
          margin-bottom: 10px;
        }

        .formulate-input-element {
          max-width: 100%;
        }

        .formulate-input-group {
          display: flex;
          .formulate-input-group-item {
            margin-right: 10px;
            margin-bottom: 0;
          }
          label {
            margin-bottom: 0;
            font-family: inherit;
            font-weight: inherit;
          }
        }
      }

      &.horizontal-label-input {
        .formulate-input-wrapper {
          display: flex;
          align-items: center;
          gap: 3rem;

          .formulate-input-element {
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    align-items: center;

    ::v-deep .formulate-input {
      margin-bottom: 0px !important;

      .formulate-input-element {
        button {
          margin-bottom: 0px;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
